




















































import Vue from "vue";
import { mapState } from "vuex";
import NewVotingSessionBtn from "@/components/NewVotingSessionBtn.vue";
import RevealCardsBtn from "@/components/RevealCardsBtn.vue";

export default Vue.extend({
  name: "PokerTable",
  components: { RevealCardsBtn, NewVotingSessionBtn },
  props: {
    session: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(["userId"]),
    isOwner(): boolean {
      return this.userId === this.session?.ownerId;
    },
    currentVotings(): Record<string, unknown>[] {
      if (
        !this.session ||
        !this.session.votings ||
        !this.session.currentVotingId
      )
        return [];
      const currentVoting = this.session.votings[this.session.currentVotingId];

      if (!currentVoting) return [];
      return currentVoting;
    }
  }
});
