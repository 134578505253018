



































import Vue from "vue";
import { onValue, push, ref, set } from "firebase/database";
import { realtimeDatabase } from "@/plugins/realtimeDatabase.ts";
import { mapState } from "vuex";
import AddParticipantDialog from "@/components/addParticipantDialog.vue";
import PokerTable from "@/components/PokerTable.vue";
import Voting from "@/components/Voting.vue";
import MobileVotings from "@/components/MobileVotings.vue";

interface session {
  name: string;
  ownerId: string;
  participants?: Record<string, { name: string }>;
  currentVotingId?: string;
  votings?: Record<string, Record<string, string | number>>;
  isRevealed: boolean;
}

interface componentData {
  session: session | null;
}

export default Vue.extend({
  components: {
    MobileVotings,
    Voting,
    PokerTable,
    AddParticipantDialog
  },
  data: (): componentData => ({
    session: null
  }),
  computed: {
    ...mapState(["userId"]),
    sessionId(): string {
      return this.$route.params.sessionId;
    },
    currentLocation(): string {
      return window.location.href;
    },
    hasJoined(): boolean {
      if (!this.session?.participants) return false;
      return this.session?.participants[this.userId] !== undefined;
    }
  },
  created() {
    this.joinSession();
  },
  methods: {
    joinSession(): void {
      this.watchSession();
    },
    watchSession(): void {
      const session = ref(realtimeDatabase, `sessions/${this.sessionId}`);
      onValue(session, snapshot => {
        this.session = snapshot.val();
      });
    },
    copySessionToClipboard() {
      navigator.clipboard.writeText(this.currentLocation);
    }
  }
});
