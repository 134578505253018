















import Vue from "vue";
import { ref, set } from "firebase/database";
import { realtimeDatabase } from "@/plugins/realtimeDatabase";
import { mapState } from "vuex";

export default Vue.extend({
  name: "Voting",
  props: {
    session: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(["userId"]),
    sessionId(): string {
      return this.$route.params.sessionId;
    },
    votingOptions() {
      if (!this.session.votingOptions) {
        return ["½", 0, 1, 2, 3, 5, 8, 13, 20, 40, 100, "?"];
      }
      return this.session.votingOptions.split(",");
    }
  },
  methods: {
    setVote(vote: string | number): void {
      if (!this.session) {
        throw new Error("No session");
      }
      if (!this.session.currentVotingId) {
        throw new Error("No current voting id");
      }

      set(
        ref(
          realtimeDatabase,
          `sessions/${this.sessionId}/votings/${this.session.currentVotingId}/${this.userId}`
        ),
        vote
      );
    }
  }
});
