var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',_vm._l((Object.entries(
        _vm.session.participants
      )),function(ref){
      var participantId = ref[0];
      var participant = ref[1];
return _c('v-col',{key:participantId,staticClass:"text-center",attrs:{"cols":"4"}},[_c('div',{staticClass:"text-truncate",attrs:{"title":participant.name}},[(participantId === _vm.session.ownerId)?_c('v-icon',[_vm._v("mdi-crown")]):_c('v-icon',[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(participant.name)+" ")],1),(_vm.currentVotings[participantId] !== undefined)?_c('div',{staticClass:"card",class:{
          revealed: _vm.session.isRevealed || participantId === _vm.userId
        }},[(_vm.session.isRevealed || participantId === _vm.userId)?_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.currentVotings[participantId])+" ")]):_vm._e()]):_vm._e()])}),1),_c('v-row',{staticClass:"justify-center mt-6"},[(_vm.isOwner && (_vm.session.isRevealed || !_vm.session.currentVotingId))?_c('NewVotingSessionBtn'):_vm._e(),(_vm.isOwner && _vm.session.currentVotingId && !_vm.session.isRevealed)?_c('RevealCardsBtn',{staticClass:"ml-3"}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }