var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"poker-wrapper"},[_c('div',{staticClass:"players"},_vm._l((Object.entries(
        _vm.session.participants
      )),function(ref,i){
      var participantId = ref[0];
      var participant = ref[1];
return _c('div',{key:i,staticClass:"player--wrapper pointer-events--all",class:("player--wrapper__" + (i + 1)),attrs:{"title":participant.name}},[_c('div',{staticClass:"player",style:(("background-color: " + (participant.color)))},[_c('div',{staticClass:"player--name",class:{ 'font-weight-bold': participantId === _vm.userId }},[(participantId === _vm.session.ownerId)?_c('v-icon',[_vm._v("mdi-crown")]):_c('v-icon',[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(participant.name)+" ")],1)]),(_vm.currentVotings[participantId] !== undefined)?_c('div',{staticClass:"card",class:{
          revealed: _vm.session.isRevealed || participantId === _vm.userId
        }},[(_vm.session.isRevealed || participantId === _vm.userId)?_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.currentVotings[participantId])+" ")]):_vm._e()]):_vm._e()])}),0),_c('div',{staticClass:"table d-flex justify-center align-center"},[(_vm.isOwner && (_vm.session.isRevealed || !_vm.session.currentVotingId))?_c('NewVotingSessionBtn',{attrs:{"color":"white"}}):_vm._e(),(_vm.isOwner && _vm.session.currentVotingId && !_vm.session.isRevealed)?_c('RevealCardsBtn',{staticClass:"ml-3",attrs:{"color":"white"}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }