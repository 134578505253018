















































import { child, push, ref, set } from "firebase/database";
import { realtimeDatabase } from "@/plugins/realtimeDatabase.ts";
import { mapState } from "vuex";
import Vue from "vue";

interface componentData {
  name: string;
  color: string;
  colorOptions: string[];
}

export default Vue.extend({
  name: "addParticipantDialog",
  data: (): componentData => ({
    name: "",
    color: "",
    colorOptions: []
  }),
  props: {
    sessionId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState(["userId"])
  },
  created() {
    this.colorOptions = this.shuffleArray([
      "#FC9918",
      "#676FA3",
      "#35589A",
      "#F14A16",
      "#FF5959",
      "#105652",
      "#B24080"
    ]) as string[];
    this.color = this.colorOptions[0];
  },
  methods: {
    save(): void {
      this.addParticipant({ name: this.name, color: this.color }, this.userId);
    },
    addParticipant(data: Record<string, unknown>, userId: string): void {
      set(
        ref(
          realtimeDatabase,
          `sessions/${this.sessionId}/participants/${userId}`
        ),
        data
      );
    },
    shuffleArray(array: unknown[]): unknown[] {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }
  }
});
